import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { getSlideById } from '@/api'
import createResponseError from '@/errors/create-response-error'
import * as Sentry from '@sentry/browser'
import slideComponents from '@/slide-components'
import MutationType from '@/constants/mutation-type'
import { resolveId, resolveSlug } from '@/utils/alias-resolver'
import { State } from 'vuex-class'
import DataObserver from '@/utils/data-observer'

Component.registerHooks(['beforeRouteEnter'])

@Component
export default class SlideWalkerLoader extends Vue {
    @Prop() id!: string

    @State transitionIsVisible!: boolean

    rawWalker: SlideWalker | null = null
    dataObserver = new DataObserver(this.fetchData.bind(this))

    get walker(): SlideWalker | null {
        if (!this.rawWalker) {
            return null
        }

        return this.getWalkerById(this.rawWalker)
    }

    get filteredChildren(): Array<SlideWalker> {
        if (!this.walker) return []

        return this.walker.children.filter(child => this.filterChild(child))
    }

    beforeRouteEnter(to: Route, from: Route, next: Function) {
        const id = to.meta?.root?.id || to.meta?.id

        if (!id) {
            next()
            return
        }

        getSlideById(id)
            .then(async response => {
                if (response.status === 200) {
                    response.json().then(result => {
                        next((vm: SlideWalkerLoader) => {
                            vm.rawWalker = result
                        })
                    })
                } else {
                    const error = await createResponseError(response)
                    next(error)
                }
            })
            .catch(error => {
                next(error)
                if (process.env.VUE_APP_SENTRY_DSN) {
                    Sentry.captureException(error)
                }
            })
    }

    mounted() {
        this.dataObserver.observe()
    }

    beforeDestroy() {
        this.dataObserver.disconnect()
    }

    getWalkerById(walker: SlideWalker): SlideWalker | null {
        const walkerId = resolveId(walker)

        if (walkerId === this.id) {
            return walker
        } else {
            for (let i = 0, n = walker.children.length; i < n; i++) {
                const result = this.getWalkerById(walker.children[i])

                if (result) return result
            }

            return null
        }
    }

    filterSlideIndex(value: number) {
        return value
    }

    filterNumberSlides(value: number) {
        return value
    }

    filterChild(child: SlideWalker) {
        return child.item['@type'] in slideComponents
    }

    fetchData() {
        const id = this.rawWalker && resolveId(this.rawWalker)

        if (this.transitionIsVisible || !id) return Promise.resolve()

        return getSlideById(id, { cache: 'no-cache' })
            .then(async response => {
                if (response.status === 200) {
                    response.json().then(result => {
                        this.rawWalker = result
                    })
                } else if (response.status === 304) {
                    // no change
                } else {
                    const error = await createResponseError(response)

                    Sentry.captureException(error)
                }
            })
            .catch(error => {
                if (process.env.VUE_APP_SENTRY_DSN) {
                    Sentry.captureException(error)
                }
            })
    }

    @Watch('filteredChildren')
    onFilteredChildrenChange() {
        if (this.$route.hash) {
            const index = this.filteredChildren.findIndex(
                child =>
                    '#' + resolveSlug(child) === this.$route.hash ||
                    child.children.find(grandchild => '#' + resolveSlug(grandchild) === this.$route.hash)
            )

            this.$store.commit(MutationType.SLIDE_INDEX, index !== -1 ? this.filterSlideIndex(index) : 0)
        } else {
            this.$store.commit(MutationType.SLIDE_INDEX, 0)
        }

        this.$store.commit(MutationType.NUMBER_SLIDES, this.filterNumberSlides(this.filteredChildren.length))
    }
}
