var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.walker)?_c('scroller-vertical',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var y = ref.y;
var endDrag = ref.endDrag;
return [_c('div',{class:_vm.$style.slide},_vm._l((_vm.filteredChildren),function(child,index){return _c(child.item['@type'],_vm._g({key:_vm.resolveWalkerSlug(child),tag:"component",attrs:{"walker":child,"index":index,"y":y,"end-drag-y":endDrag,"should-init":_vm.allSlidesMounted},on:{"hook:mounted":_vm.onSlideMounted}},{
                    'assets-loaded': _vm.onCurrentSlideAssetsLoaded,
                    'cancel-drag': function (event) {
                        endDrag(event)
                    }
                }))}),1)]}}],null,false,3531754182)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }