


























import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import SlideWalkerLoader from '@/mixins/SlideWalkerLoader'
import slideComponents from '@/slide-components'
import RootSlide from '@/mixins/RootSlide'
import ScrollerVertical from '@/components/ScrollerVertical.vue'
import { State } from 'vuex-class'
import EventType from '@/constants/event-type'
import SlideType from '@/constants/slide-type'
import { resolveSlug } from '@/utils/alias-resolver'

const NOT_ALLOWED_SLIDE_TYPES = [SlideType.SECTION, SlideType.CONTAINER]

@Component({
    components: {
        ...slideComponents,
        ScrollerVertical
    }
})
export default class SlideSection extends mixins(SlideWalkerLoader, RootSlide) {
    @State('slideIndex') currentSlideIndex!: number
    @State numberSlides!: number

    numMountedSlides = 0
    allSlidesMounted = false

    mounted() {
        if (!this.walker?.children.length) {
            this.dispatchAssetsLoaded()
        }
    }

    resolveWalkerSlug(walker: SlideWalker | PresentationWalker) {
        return resolveSlug(walker)
    }

    filterChild(child: SlideWalker) {
        return (
            child.item['@type'] in slideComponents &&
            !NOT_ALLOWED_SLIDE_TYPES.includes(child.item['@type'] as SlideType)
        )
    }

    dispatchAssetsLoaded() {
        this.$emit(EventType.ASSETS_LOADED)
    }

    onCurrentSlideAssetsLoaded() {
        this.dispatchAssetsLoaded()
    }

    onSlideMounted() {
        this.numMountedSlides++
        this.allSlidesMounted = this.numMountedSlides === this.filteredChildren.length
    }
}
