






import { Component, Vue, Watch } from 'vue-property-decorator'
import gsap from 'gsap'
import { Getter, State } from 'vuex-class'
import eventBus from '@/utils/event-bus'
import EventType from '@/constants/event-type'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import { Route } from 'vue-router'
import MutationType from '@/constants/mutation-type'

gsap.registerPlugin(Draggable, InertiaPlugin)

@Component
export default class ScrollerVertical extends Vue {
    @State('slideIndex') currentSlideIndex!: number
    @State numberSlides!: number
    @State isSlideshow!: boolean
    @State transitionIsVisible!: boolean

    @Getter navigationIsAllowed!: boolean
    @Getter hasActiveTour!: boolean

    y = 0
    windowWidth = window.innerWidth
    windowHeight = window.innerHeight
    invalidated = false
    isInitSlideIndex = false

    drag!: Draggable
    ySetter!: Function

    get bounds(): Draggable.BoundsMinMax {
        return {
            minX: 0,
            maxX: 0,
            minY: (this.numberSlides - 1) * -this.windowHeight,
            maxY: 0
        }
    }

    mounted() {
        if (this.isSlideshow) {
            this.initSlideshow()
            this.updatePosition({ duration: 0 })
        }

        eventBus.$on(EventType.RESIZE, this.onResize)
    }

    updated() {
        if (this.invalidated && this.isSlideshow) {
            this.updatePosition({ duration: 0 })
        }

        this.invalidated = false
    }

    beforeDestroy() {
        eventBus.$off(EventType.RESIZE, this.onResize)

        this.disposeSlideshow()
    }

    initSlideshow() {
        window.addEventListener('keyup', this.onKeyUp)

        eventBus.$on(EventType.GOTO_NEXT_SLIDE, this.onGotoNextSlide)
        eventBus.$on(EventType.GOTO_PREVIOUS_SLIDE, this.onGotoPreviousSlide)

        this.initDrag()
    }

    initDrag(): void {
        const proxy = document.createElement('div')
        const options: GSAPDraggableVars = {
            type: 'y',
            bounds: this.bounds,
            trigger: this.$el,
            inertia: true,
            throwResistance: 3000,
            allowContextMenu: true,
            dragResistance: 0.4,
            dragClickables: false, // allow touch devices to interact with elements (e.g. scroll the cheat sheet)
            edgeResistance: 0.8,
            zIndexBoost: false,
            onDrag: this.onDrag,
            onDragEnd: this.onDragEnd,
            onThrowUpdate: this.onThrowUpdate,
            snap: this.snapDrag
        }

        if (!this.ySetter) this.ySetter = gsap.quickSetter(proxy, 'y', 'px')

        this.drag = Draggable.create(proxy, options)[0]

        if (!this.navigationIsAllowed || this.hasActiveTour) this.drag.enabled(false)
    }

    snapDrag(value: number): number {
        return Math.round(value / this.windowHeight) * this.windowHeight
    }

    disposeSlideshow() {
        window.removeEventListener('keyup', this.onKeyUp)

        eventBus.$off(EventType.GOTO_NEXT_SLIDE, this.onGotoNextSlide)
        eventBus.$off(EventType.GOTO_PREVIOUS_SLIDE, this.onGotoPreviousSlide)

        if (this.drag) this.drag.kill()
    }

    updatePosition({ duration = 0.9 } = {}) {
        if (this.drag.tween) this.drag.tween.pause()

        gsap.to(this, {
            y: this.currentSlideIndex * this.windowHeight * -1,
            duration,
            ease: 'power3.inOut',
            onUpdate: () => {
                this.ySetter(this.y)
                this.drag.update()
            }
        })
    }

    gotoNextSlide() {
        const index = this.currentSlideIndex + 1

        if (index > this.numberSlides - 1) return

        this.gotoSlide(index)
    }

    gotoPreviousSlide() {
        const index = this.currentSlideIndex - 1

        if (index < 0) return

        this.gotoSlide(index)
    }

    gotoSlide(index: number) {
        this.$store.commit(MutationType.SLIDE_INDEX, index)
    }

    updateDragEnabled() {
        if (!this.drag) return

        this.drag.enabled(!this.hasActiveTour && this.navigationIsAllowed)
    }

    endDrag(event: MouseEvent) {
        if (this.drag) this.drag.endDrag(event)
    }

    onResize() {
        gsap.killTweensOf(this, { y: true })

        this.windowWidth = window.innerWidth
        this.windowHeight = window.innerHeight

        this.y = this.currentSlideIndex * -this.windowHeight
    }

    onKeyUp(event: KeyboardEvent) {
        if (!this.navigationIsAllowed || this.hasActiveTour) return

        switch (event.key) {
            case 'ArrowDown':
                this.gotoNextSlide()
                break

            case 'ArrowUp':
                this.gotoPreviousSlide()
                break
        }
    }

    onDrag() {
        this.y = this.drag.y
    }

    onThrowUpdate() {
        this.y = this.drag.y
    }

    onDragEnd() {
        const index = Math.floor(this.drag.endY / -this.windowHeight)
        const ratio = (this.drag.endY - this.drag.y) / this.windowHeight

        if (index === this.currentSlideIndex && Math.abs(ratio) > 0.15) {
            this.$nextTick(() => {
                if (ratio < 0) this.gotoPreviousSlide()
                else this.gotoNextSlide()
            })
        } else {
            this.$store.commit(MutationType.SLIDE_INDEX, index)
        }
    }

    @Watch('currentSlideIndex')
    onCurrentSlideIndexChange() {
        if (!this.isSlideshow) return

        const index = Math.floor(this.drag.endY / -this.windowHeight)

        if (this.drag.tween && this.drag.tween.isActive() && index === this.currentSlideIndex) return

        this.updatePosition(this.transitionIsVisible ? { duration: 0 } : {})
    }

    @Watch('isSlideshow')
    onIsSlideshowChange() {
        if (!this.isSlideshow) {
            this.disposeSlideshow()

            gsap.killTweensOf(this, { y: true })
        } else {
            window.scrollTo(0, 0)

            this.initSlideshow()
        }
    }

    @Watch('navigationIsAllowed')
    onNavigationIsAllowedChange() {
        this.updateDragEnabled()
    }

    @Watch('hasActiveTour')
    onHasActiveTourChange() {
        this.updateDragEnabled()
    }

    onGotoNextSlide() {
        this.gotoNextSlide()
    }

    onGotoPreviousSlide() {
        this.gotoPreviousSlide()
    }

    @Watch('numberSlides')
    onNumberSlidesChange() {
        if (this.drag) {
            this.$nextTick(() => {
                this.drag.applyBounds(this.bounds)
                this.drag.update()
            })
        }
    }

    @Watch('$route')
    onRouteChange(currentRoute: Route, previousRoute: Route) {
        this.invalidated = currentRoute.path !== previousRoute.path
    }
}
